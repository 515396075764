import React from 'react';
import {NavLink} from 'react-router-dom';
import {Link} from 'react-router-dom';
import IconLogoDriving from '../assets/logo-audi-driving-experience.svg';
import IconLogo from '../assets/logo-audi.svg';

const DrawerLeft = ({data, setShowPopup}) => {
    const orderReference = ['accueil', 'circuit', 'freinage', 'trajectoire', 'gamme', 'medias'];
    const sortedData = data.sort((a, b) => orderReference.indexOf(a.identifier) - orderReference.indexOf(b.identifier));

    return (
        <div className="drawer-container fixed top-0 left-0 h-screen w-64 bg-white shadow-md flex flex-col justify-between">
            <div>
                <div className="h-32 w-full flex bg-red-800 items-center justify-center">
                    <Link to="/">
                        <img src={IconLogo} alt="logo-audi" style={{maxHeight: '160px'}}/>
                    </Link>
                </div>
                <div className="flex flex-col justify-center items-center mt-16">
                    <ul className="text-sm flex flex-col w-full">
                        {sortedData.map((item, index) => {
                            return (
                                <li className="items-center hover:text-red-800">
                                    <NavLink className={({isActive}) => `${isActive ? 'text-red-800' : ''} flex justify-start py-6 px-8`} to={item.url} key={index}>
                                        <img src={`./static/${item.icone}`} alt="Home" className="h-6 w-6 mr-2" style={{maxHeight: '16px', maxWidth: '16px'}}/>
                                        <span className="font-AudiTypeExtendedNormal leading-none">
                                          {item.libelle}
                                        </span>
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div className="flex justify-center items-end pb-10">
                <img src={IconLogoDriving} style={{width: '153px', height: '32px'}} className="mb-4 cursor-pointer	" alt="logo-driving-experience" onClick={() => setShowPopup(true)}/>
            </div>
        </div>
    );
};

export default DrawerLeft;
