import {useEffect, useState} from 'react';
import Slider from 'react-slick';
import IconBackCircle from '../assets/icons/back-circle.svg';
import IconNextCircle from '../assets/icons/next-circle.svg';
import BackTitle from './BackTitle';
import Loader from './Loader';

const TrajectoryPage = ({data}) => {
    const [trajectoryData, setTrajectoryData] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);

    const Arrow = ({className, style, onClick, type}) => {
        return (
            <img src={'prev' === type ? IconBackCircle : IconNextCircle} className={className} style={{
                ...style,
                left: 'prev' === type ? 10 : 'unset',
                right: 'next' === type ? 10 : 'unset',
                zIndex: 1,
            }} onClick={onClick} alt={type === 'prev' ? 'fleche précédent' : 'fleche suivant'}/>
        );
    };

    const Div = ({children}) => {
        return <div className="flex gap-x-2">{children}</div>;
    };

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        slideToShow: 1,
        slideToScroll: 1,
        nextArrow: <Arrow type="next"/>,
        prevArrow: <Arrow type="prev"/>,
        centerMode: true,
    };

    useEffect(() => {
        setTrajectoryData(data.contents);
        setBannerImage(data.bannerImage);
    }, [data]);

    if (!trajectoryData) {
        return <Loader/>;
    }

    return (
        <div className="block flex-col items-center">
            <div className="w-auto">
                <div className="banner-container" dangerouslySetInnerHTML={{__html: bannerImage}}/>
            </div>
            <div style={{
                padding: '30px 0 10px 20px',
            }}>
                <BackTitle title="Trajectoire"/>
            </div>
            <div style={{
                width: '90%',
                margin: 'auto',
            }}>
                <Slider {...settings}>
                    {trajectoryData.map((trajectory, index) => {
                        return (
                            <Div key={index} className="pt-10">
                                <div className="w-1/3 flex flex-col justify-center gap-y-5">
                                    <h1 className="text-4xl font-bold pl-14 font-AudiTypeExtendedNormal">
                                        {trajectory.name}
                                    </h1>
                                    <p className="pl-14 font-AudiTypeWideLight" dangerouslySetInnerHTML={{__html: trajectory.description}}/>
                                </div>
                                <div className={`w-2/3 max-h-72 ${
                                    window.innerWidth >= 1600 ? 'md:max-h-72' : ''
                                } flex justify-center items-center`}>
                                    <video src={`./static/${trajectory.video}`} loop autoPlay muted className="h-72"/>
                                </div>
                            </Div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default TrajectoryPage;
