import React from 'react';

const BgHeader = ({image}) => {
    return (
        <div className="w-full">
            <div className="w-full">
                <img className="w-full h-full object-cover" src={`./static/${image}`} alt="Bandeau header"/>
            </div>
        </div>
    );
};

export default BgHeader;
