import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import BackTitle from './BackTitle';
import Loader from './Loader';

const GammePage = ({data}) => {
    const [gammesData, setGammesData] = useState(null);
    const [hover, setHover] = useState(null);

    useEffect(() => {
        setGammesData(data.contents);
    }, [data]);

    if (!gammesData) {
        return <Loader/>;
    }

    return (
        <div className="block flex-col items-center">
            <div style={{
                padding: '70px 20px',
            }}>
                <BackTitle title="La gamme Audi"/>
            </div>
            <div style={{
                width: '95%',
                margin: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px',
                justifyContent: 'center',
            }}>
                {gammesData.map((gamme, index) => {
                    return (
                        <Link key={index} to={`/gamme/${gamme.id}`} state={{model: gamme.name}} onMouseEnter={() => setHover(gamme.id)} onMouseLeave={() => setHover(null)}>
                            <img src={`./static/${gamme.image}`} alt={gamme.name}/>
                            <button className="py-2 px-7 mt-3" style={{
                                fontSize: 12,
                                border: '0.5px solid #434343',
                                transition: '0.4s all ease-in-out',
                                opacity: hover === gamme.id ? '1' : '0',
                            }}>
                                Plus d'informations
                            </button>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default GammePage;
