import React from 'react';
import IconLoader from '../assets/loader.gif';

const Loader = () => {
    return (
        <div className="flex justify-center items-center h-screen">
            <img className="w-28 h-28" src={IconLoader} alt="loading..."/>
        </div>
    );
};

export default Loader;
