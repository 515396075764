import {Link} from 'react-router-dom';
import IconBack from '../assets/icons/back.svg';

export default function BackTitle({title}) {
    return (
        <div className="flex items-center h-16">
            <Link to="/" className="flex items-center">
                <img src={IconBack} alt="Retour" className="h-8 w-4 pl-1"/>
                <h1 className={`text-4xl font-normal ml-12 font-AudiTypeExtendedNormal`} style={{lineHeight: '0.1'}}>
                    {title}
                </h1>
            </Link>
        </div>
    );
}
