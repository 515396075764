import {useState} from 'react';

const SettingsModal = ({showPopup, setShowPopup}) => {
    const [message, setMessage] = useState(null);

    /* eslint-disable-next-line no-undef */
    const standaloneUrl = `${APP_FRONT_URL}/standalone.zip`;

    /* eslint-disable-next-line no-undef */
    const syncDate = APP_SYNC_DATE;

    const messages = {
        sending: 'Transmission de la demande de synchronisation …',
        201: 'Votre demande de synchronisation a été prise en compte, merci rafraîchir la page dans quelques minutes.',
        429: 'Votre demande n\'a pas été prise en compte, merci de patienter 1h entre chaque synchronisation.',
        404: 'Votre demande n\'a pas été prise en compte, une erreur inconnue s\'est produite.',
    };

    const sync = async () => {
        setMessage(messages.sending);
        try {
            /* eslint-disable-next-line no-undef */
            const response = await fetch(`${APP_FRONT_URL}/sync`, {method: 'POST'});
            const statusCode = response.status;
            if (messages.hasOwnProperty(statusCode)) {
                setMessage(messages[statusCode]);
            }
        } catch (e) {
        }
    };

    return (
        <div style={{
            opacity: showPopup ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
        }}>
            {showPopup && (
                <div style={{zIndex: 999999}} className="absolute bottom-0 left-0 h-screen w-screen bg-black bg-opacity-70 flex items-center justify-center" onClick={() => setShowPopup(false)}>
                    <div onClick={e => e.stopPropagation()} className="bg-white p-6 rounded-lg shadow-md text-center flex flex-col gap-4">
                        <h2 className="text-lg font-semibold mb-4">Paramètres</h2>
                        <p className={'text-slate-400'}>Dernière mise à jour : {syncDate}</p>
                        <p>Vous rencontrez un problème ?</p>
                        <div className="px-4 flex justify-center gap-4">
                            <button onClick={() => document.location.reload()} className="bg-red-600 text-white px-4 py-2 rounded-lg">
                                Rafraîchir la page
                            </button>
                        </div>
                        <hr/>
                        {navigator.onLine && (
                            <>
                                <p>Vous avez besoin d'une version hors ligne ?</p>
                                <div className="px-4 flex justify-center gap-4">
                                    <a href={standaloneUrl} className="bg-red-600 text-white px-4 py-2 rounded-lg">
                                        Télécharger
                                    </a>
                                </div>
                                <hr/>
                                <p>Vous avez besoin de mettre à jour les informations ?</p>
                                {message ? (
                                    <p>{message}</p>
                                ) : (
                                    <div className="px-4 flex justify-center gap-4">
                                        <button onClick={sync} className="bg-red-600 text-white px-4 py-2 rounded-lg">
                                            Synchroniser
                                        </button>
                                    </div>
                                )}
                                <hr/>
                            </>
                        )}
                        <div className="px-4 flex justify-center gap-4">
                            <button onClick={() => setShowPopup(false)} className="border px-4 py-2 rounded-lg">
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SettingsModal;
