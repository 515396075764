import React, {useEffect} from 'react';

const HomePage = ({data}) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className="h-screen w-full bg-left bg-cover" style={{backgroundImage: `url(./static/${data.contents.img})`}}></div>
    );
};

export default HomePage;
