import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ComposableMap, Geographies, Geography, Marker} from 'react-simple-maps';
import mapFrance from '../assets/maps/france.json';
import IconBack from '../assets/icons/back.svg';
import Loader from './Loader';

const CircuitsPage = ({data}) => {
    const [currentCircuitHover, setCurrentCircuitHover] = useState(null);
    const [circuits, setCircuits] = useState(null);

    useEffect(() => {
        setCircuits(data.contents);
    }, [data]);

    if (!circuits) {
        return <Loader/>;
    }

    return (
        <div className="flex flex-col justify-center pt-24">
            <div className="flex items-center h-16 pl-5 pb-12">
                <Link to="/" className="flex items-center">
                    <img src={IconBack} alt="retour" className="h-8 w-4 pl-1"/>
                    <h1 className={`text-4xl font-normal ml-12 font-AudiTypeExtendedNormal`} style={{lineHeight: '0.1'}}>
                        Les circuits
                    </h1>
                </Link>
            </div>
            <div className="flex items-start justify-center bottom-0 pt-5">
                <div className="w-1/3 py-4 px-8 pl-20 pt-10">
                    <div className="mb-2 font-AudiTypeWideLight">
                        Sélectionner un circuit :
                    </div>
                    <div className="bg-stone-200 w-3/4 h-0.5 mt-5 mb-5"></div>
                    <div className="flex flex-col h-full w-25">
                        {circuits.map((circuit) => (
                            <Link state={{data: circuit}} to={`/circuit/${circuit.id}`} key={circuit.id} style={{width: 'fit-content'}}>
                                <div onMouseLeave={() => setCurrentCircuitHover(null)} onMouseEnter={() => setCurrentCircuitHover(circuit.id)} className={`circuit-item py-1 font-AudiTypeWideNormal text-sm ${
                                    currentCircuitHover === circuit.id
                                        ? 'text-red-500'
                                        : 'text-black'
                                }`}>
                                    <span>{circuit.name}</span>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="w-2/3 flex items-left justify-self-start pr-30" style={{maxHeight: '700px'}}>
                    <ComposableMap projection="geoMercator" projectionConfig={{
                        scale: 2200,
                        center: [4, 46],
                    }}>
                        <Geographies geography={mapFrance} outline="none">
                            {({geographies}) =>
                                geographies.map((geo) => {
                                    if (geo.id === 'FRA') {
                                        return (
                                            <Geography key={geo.rsmKey} geography={geo} fill="white" stroke="#000000" style={{
                                                default: {outline: 'none'},
                                                hover: {outline: 'none'},
                                                pressed: {outline: 'none'},
                                            }}/>
                                        );
                                    }
                                    return null;
                                })
                            }
                        </Geographies>
                        {circuits.map((circuit) => (
                            <Link state={{data: circuit}} onMouseLeave={() => setCurrentCircuitHover(null)} onMouseEnter={(e) => {
                                setCurrentCircuitHover(circuit.id);
                            }} key={circuit.id} to={`/circuit/${circuit.id}`}>
                                <Marker coordinates={circuit.coordinates}>
                                    <circle cx={0} cy={0} r={8} fill={
                                        currentCircuitHover === circuit.id ? '#a7252b' : '#c6c6c6'
                                    }/>
                                </Marker>
                            </Link>
                        ))}
                    </ComposableMap>
                </div>
            </div>
        </div>
    );
};

export default CircuitsPage;
