import React, {useState} from 'react';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import BrakingPage from './components/BrakingPage';
import CircuitDetail from './components/CircuitDetail';
import CircuitsPage from './components/CircuitsPage';
import DrawerLeft from './components/DrawerLeft';
import GammePage from './components/GammePage';
import GammePageList from './components/GammePageList';
import HomePage from './components/HomePage';
import MediasPage from './components/MediasPage';
import SettingsModal from './components/SettingsModal';
import TrajectoryPage from './components/TrajectoryPage';

const App = () => {
    const [showPopup, setShowPopup] = useState(false);

    /* eslint-disable-next-line no-undef */
    const data = APP_DATA;

    return (
        <Router>
            <div className="">
                <DrawerLeft data={data} setShowPopup={setShowPopup}/>
                <div style={{
                    width: 'calc(100% - 16rem)',
                    marginLeft: '16rem',
                }}>
                    <Routes>
                        <Route exact path="/" element={<HomePage data={data.find(e => e.identifier === 'accueil')}/>}/>
                        <Route path="/circuit" element={<CircuitsPage data={data.find(e => e.identifier === 'circuit')}/>}/>
                        <Route path="/freinage" element={<BrakingPage data={data.find(e => e.identifier === 'freinage')}/>}/>
                        <Route path="/trajectoire" element={<TrajectoryPage data={data.find(e => e.identifier === 'trajectoire')}/>}/>
                        <Route path="/gamme" element={<GammePage data={data.find(e => e.identifier === 'gamme')}/>}/>
                        <Route path="/gamme/:id" element={<GammePageList data={data.find(e => e.identifier === 'gamme')}/>}/>
                        <Route path="/medias" element={<MediasPage data={data.find(e => e.identifier === 'medias')}/>}/>
                        <Route path="/circuit/:id" element={<CircuitDetail/>}/>
                    </Routes>
                </div>
                <SettingsModal showPopup={showPopup} setShowPopup={setShowPopup}/>
            </div>
        </Router>
    );
};

export default App;
