import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import IconBack from '../assets/icons/back.svg';
import {getIdFromUrl} from '../js/utils/get-id-from-url';
import Loader from './Loader';

const GammePageList = (props) => {
    const [gammesData, setGammesData] = useState(null);
    const [currentGamme, setCurrentGamme] = useState(null);
    const {state} = useLocation();

    useEffect(() => {
        const id = getIdFromUrl(window.location.href);
        setGammesData(props.data.contents.find((e) => e.id === id)?.contents);
    }, [props]);

    if (!gammesData) {
        return <Loader/>;
    }

    return (
        <div style={{
            display: 'flex',
            minHeight: '100vh',
            maxHeight: '100vh',
        }}>
            <div style={{
                display: currentGamme ? 'grid' : 'block',
                gridTemplateRows: currentGamme ? 'max-content 1fr' : 'none',
            }} className={`block flex-col items-center ${
                currentGamme ? 'w-1/2' : 'w-full'
            }`}>
                <div style={{
                    padding: '70px 20px',
                }}>
                    <div className="flex items-center h-16">
                        {currentGamme ? (
                            <span className="flex items-center cursor-pointer" onClick={() => setCurrentGamme(null)}>
                <img src={IconBack} alt="retour" className="h-8 w-4 pl-1 cursor-pointer"/>
                <h1 className={`text-4xl font-normal ml-12 font-AudiTypeExtendedNormal`} style={{lineHeight: '35px', maxWidth: '400px'}}>
                  {currentGamme ? currentGamme.name : `Audi ${state.model}`}
                </h1>
              </span>
                        ) : (
                            <Link to="/gamme" className="flex items-center">
                                <img src={IconBack} alt="retour" className="h-8 w-4 pl-1"/>
                                <h1 className={`text-4xl font-normal ml-12 font-AudiTypeExtendedNormal`} style={{lineHeight: '35px', maxWidth: '400px'}}>
                                    {currentGamme ? currentGamme.name : `Audi ${state.model}`}
                                </h1>
                            </Link>
                        )}
                    </div>
                </div>
                <div style={{
                    width: '95%%',
                    height: currentGamme ? '100%' : 'auto',
                    margin: 'auto',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '30px',
                    paddingBottom: currentGamme ? '30px' : '',
                }}>
                    {currentGamme ? (
                        <>
                            <div className="flex">
                                <div className="items-start" style={{
                                    padding: '0 calc(3rem + 36px)',
                                    display: 'grid',
                                    gridTemplateRows: 'max-content max-content max-content 1fr',
                                }}>
                                    <p className="font-AudiTypeWideLight" dangerouslySetInnerHTML={{
                                        __html: currentGamme.description,
                                    }} style={{fontSize: 14, paddingTop: 30}}/>
                                    <div style={{
                                        height: 1,
                                        width: '100%',
                                        backgroundColor: '#000000',
                                        marginTop: 35,
                                        marginBottom: 35,
                                    }}/>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }} className="text-center">
                                        <p style={{fontSize: 30}} className="font-bold text-xl font-AudiTypeExtendedBold mb-10">
                                            {currentGamme.chevaux} ch
                                        </p>
                                        <p style={{fontSize: 30}} className="font-bold mb-1 text-xl font-AudiTypeExtendedBold">
                                            {currentGamme.perf} s
                                        </p>
                                        <p style={{fontSize: 14}} className="mb-8 text-xs font-AudiTypeExtendedNormal">
                                            de 0 à 100 km/h
                                        </p>
                                        <p style={{fontSize: 30}} className="font-bold mb-1 text-xl font-AudiTypeExtendedBold">
                                            {currentGamme.vmax} km/h
                                        </p>
                                        <p style={{fontSize: 14}} className="text-xs font-AudiTypeExtendedNormal">
                                            de vitesse de pointe
                                        </p>
                                    </div>
                                    <div style={{
                                        left: 0,
                                        width: '65%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}>
                                        <img style={{maxHeight: '150px', maxWidth: '150px'}} src={`./static/${currentGamme.imageEco}`} alt="img-eco"/>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        gammesData.map((gamme, index) => {
                            const image = `${gamme.image}`;
                            const numberOfImages = gammesData.length;
                            let imageSizeClass = 'h-40';

                            if (numberOfImages >= 6) {
                                imageSizeClass = 'h-24';
                            } else if (numberOfImages >= 4 && numberOfImages < 6) {
                                imageSizeClass = 'h-32';
                            }

                            return (
                                <div key={index}>
                                    <div style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }} onClick={() => setCurrentGamme(gamme)}>
                                        <img className={imageSizeClass} src={`./static/${image}`} alt={gamme.name}/>
                                        <div className="text-center">
                                            <p style={{fontSize: '21px'}}>{gamme.name}</p>
                                            <button className="py-2 px-7 mt-3" style={{fontSize: 12, border: '0.5px solid #434343'}}>
                                                Plus d'informations
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
            {currentGamme ? (
                <div className="w-1/2 flex flex-col left-0">
                    <div className={
                        currentGamme.imagePrincipale2 && currentGamme.imagePrincipale3
                            ? 'h-2/3 w-full'
                            : 'h-full w-full'
                    }>
                        <img src={`./static/${currentGamme.imagePrincipale}`} style={{height: '100%', width: '100%', objectFit: 'cover'}} alt="imgprincipale"/>
                    </div>
                    {currentGamme.imagePrincipale2 && currentGamme.imagePrincipale3 ? (
                        <div className="flex h-1/3 w-full">
                            <img src={`./static/${currentGamme.imagePrincipale2}`} style={{
                                height: '100%',
                                width: currentGamme.imagePrincipale3 ? '50%' : '100%',
                                objectFit: 'cover',
                            }} alt="imgprincipale2"/>
                            {currentGamme.imagePrincipale3 && (
                                <img src={`./static/${currentGamme.imagePrincipale3}`} style={{height: '100%', width: '50%', objectFit: 'cover'}} alt="imgprincipale3"/>
                            )}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default GammePageList;
