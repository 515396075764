import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import Slider from 'react-slick';
import IconBackCircle from '../assets/icons/back-circle.svg';
import IconClose from '../assets/icons/close.svg';
import IconMedia from '../assets/icons/media.svg';
import IconNextCircle from '../assets/icons/next-circle.svg';
import BackTitle from './BackTitle';
import Loader from './Loader';

const MediasPage = ({data}) => {
    const [medias, setMedias] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [videoLoaded, setVideoLoaded] = useState(false);

    useEffect(() => {
        setMedias(data.contents);
    }, [data]);

    const Arrow = ({className, style, onClick, type}) => {
        return (
            <img src={'prev' === type ? IconBackCircle : IconNextCircle} className={className} onClick={onClick} alt={type === 'prev' ? 'fleche précédent' : 'fleche suivant'}/>
        );
    };

    const settings = {
        className: 'carousel-media',
        infinite: false,
        slidesToShow: 3.2,
        rows: 2,
        nextArrow: <Arrow type="next"/>,
        prevArrow: <Arrow type="prev"/>,
    };

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
            maxWidth: '80%',
            maxHeight: '80%',
            padding: '0',
            borderRadius: '0',
            backgroundColor: 'transparent',
            border: 'none',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            transition: 'background-color 0.2s ease-in-out',
        },
    };

    const handleMediaClick = (media) => {
        setSelectedMedia(media);
        setModalIsOpen(true);
        setVideoLoaded(false);
    };

    const closeModal = () => {
        setSelectedMedia(null);
        setModalIsOpen(false);
    };

    if (!medias) {
        return <Loader/>;
    }

    return (
        <div>
            <div style={{
                padding: '70px 0 50px 20px',
            }}>
                <BackTitle title="Médias"/>
            </div>
            <div style={{
                width: 'calc(100% - 144px)',
                margin: 'auto',
            }}>
                <div className="h-px bg-black w-96 mb-3"/>
                <Slider {...settings}>
                    {medias.map((media, i) => (
                        <div key={i} className="flex flex-col gap-x-2 pr-5 pt-5 pb-5 font-AudiTypeExtendedNormal">
                            <div onClick={() => handleMediaClick(media)} style={{cursor: 'pointer', position: 'relative'}}>
                                {media.type === 'video/mp4' ? (
                                    <>
                                        <video src={`./static/${media.filePath}`} style={{
                                            objectFit: 'cover',
                                            display: 'block',
                                            height: '200px',
                                            width: '100%',
                                        }}/>
                                        <span style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            width: '100%',
                                        }}>
                      <img src={IconMedia} alt="mediaplayer svg"/>
                    </span>
                                    </>
                                ) : (
                                    <img src={`./static/${media.filePath}`} alt={media.name} style={{
                                        objectFit: 'cover',
                                        display: 'block',
                                        height: '200px',
                                        width: '100%',
                                    }}/>
                                )}
                                <p className="pt-1 font-AudiTypeExtendedNormal whitespace-nowrap overflow-hidden text-ellipsis">
                                    {media.name}
                                </p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} ariaHideApp={false} style={customModalStyles} contentLabel="Media Modal">
                {selectedMedia && (
                    <>
                        <div style={{
                            width: '100%',
                            backgroundColor: 'transparent',
                            display: 'flex',
                            justifyContent: 'space-between',
                            opacity: videoLoaded ? 1 : 0,
                            transition: 'opacity 0.3s ease-in-out',
                        }}>
                            <h2 className="text-xl font-AudiTypeExtendedNormal text-white">
                                {selectedMedia.name}
                            </h2>
                            <span onClick={closeModal} style={{cursor: 'pointer'}} className="w-6 h-6">
                <img src={IconClose} alt="close svg" className="w-6 h-6"/>
              </span>
                        </div>
                        {selectedMedia.type === 'video/mp4' ? (
                            <video autoPlay loop controls style={{
                                objectFit: 'contain',
                                opacity: videoLoaded ? 1 : 0,
                                transition: 'opacity 0.3s ease-in-out',
                            }} onLoadedData={() => setVideoLoaded(true)}>
                                <source src={`./static/${selectedMedia.filePath}`} type={selectedMedia.type}/>
                            </video>
                        ) : (
                            <img src={`./static/${selectedMedia.filePath}`} alt={selectedMedia.name} style={{
                                width: '100%',
                                height: '100%',
                                opacity: videoLoaded ? 1 : 0,
                                transition: 'opacity 0.3s ease-in-out',
                            }}/>
                        )}
                    </>
                )}
            </Modal>
        </div>
    );
};

export default MediasPage;
